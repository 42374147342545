import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import mapboxgl, { LngLat } from 'mapbox-gl';
import { MapComponent as MapboxMap } from 'ngx-mapbox-gl';

@Component({
  selector: 'lib-map',
  templateUrl: './map.component.html',
  styles: []
})
export class MapComponent {
  @Input() userLocation: any;
  @Input() zoomLevel: number;
  @Input() fitBounds = true;
  @Input() locations: any[];
  @Input() locationsMap: boolean;
  @Input() confirmLocation: boolean;

  @ViewChild('map') map: MapboxMap;

  mapLoaded = false;

  bounds: any;

  constructor() {}

  onMapLoad() {
    this.mapLoaded = true;
    const initBounds = new mapboxgl.LngLatBounds();
    this.locations.forEach(location => {
      initBounds.extend([location.longitude, location.latitude]);
    });
    this.bounds = initBounds;

    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([this.userLocation.longitude, this.userLocation.latitude]);
    bounds.extend([this.locations[0].longitude, this.locations[0].latitude]);
    this.bounds = bounds;
  }
}
