<div *ngIf="(app$ | async).banner as banner" class="col-12 align-items-center headerHeroScroll" id="headerHero1">
  <div *ngIf="user$ | async as user">
    <div class="d-flex flex-row headerStuff headerStuffScroll" id="headerStuff">
      <ng-container>
        <div class="col leftScroll" id="left">
          <a
            *ngIf="banner.locationName == ''"
            class=" clickable mobileSmallBackScroll d-flex flex-row"
            id="mobileSmallBack"
            (click)="navBack(banner.text)"
          >
            <lib-svg-sprite *ngIf="banner.canGoBack" svgID="back-button"></lib-svg-sprite>
            <p
              class="pageHeaderScroll d-none d-md-block"
              [ngClass]="(banner.text === 'MENU' || banner.text === 'CUSTOMIZE') && user.info ? 'mobile-fave-link' : ''"
              id="pageHeader"
              style="text-transform: uppercase;"
            >
              {{ banner.text }}
            </p>
          </a>

          <ng-container *ngIf="banner.locationName != ''">
            <a class="clickable mobileSmallBackScroll" (click)="goBackLocList()">
              <lib-svg-sprite svgID="back-button"></lib-svg-sprite>
              <span class="subLocName">{{ banner.locationName }}</span>
            </a>
          </ng-container>
        </div>
        <div *ngIf="user.info" class="favOrders">
          <button
            *ngIf="banner.text === 'MENU' || banner.text === 'CUSTOMIZE'"
            class="col favOrderBtn me-0 me-lg-3"
            large="true"
            id="right"
            (click)="navigateToRecent()"
          >
            <small class="d-inline-block d-lg-none bold"
              ><span style="font-size: 10px" class="bold">Recent</span><br />Orders</small
            >
            <span class="d-none d-lg-inline-block bold">Recent Orders</span>
          </button>
          <button
            *ngIf="banner.text === 'MENU' || banner.text === 'CUSTOMIZE'"
            class="col favOrderBtn me-0 me-lg-3"
            large="true"
            id="right"
            (click)="navigateToFave()"
          >
            <small class="d-inline-block d-lg-none bold"
              ><span style="font-size: 10px" class="bold">Favorite</span><br />Orders</small
            >
            <span class="d-none d-lg-inline-block bold">Favorite Orders</span>
          </button>
          <button
            *ngIf="banner.text === 'MENU' || banner.text === 'CUSTOMIZE'"
            class="col favOrderBtn me-0 me-lg-3 bold"
            id="right"
            (click)="navigateToSavedProducts()"
          >
            <small class="d-inline-block d-lg-none bold"
              ><span style="font-size: 10px" class="bold">Favorite</span><br />Items</small
            >
            <span class="d-none d-lg-inline-block bold">Favorite Items</span>
          </button>
        </div>
        <div class="col rightScroll" *ngIf="mode === 'kms' && this.router.url.includes('start-order')">
          <div class="ms-auto">
            <button (click)="routeToQueue()" class="returnToKioskBtn btn p-2" *ngIf="!(isMaster$ | async)">
              Live Fire
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
