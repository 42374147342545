import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PrinterDeviceService } from '@app/providers/expo/printer/printer-device.service';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { firstValueFrom } from 'rxjs';
import { KioskContent } from '@app/providers/expo/directus/directus-collections.interface';
import { environment } from '@env/environment';

declare var StarWebPrintBuilder: any;
declare var StarWebPrintTrader: any;

interface ICanvasData {
  fontSize: number;
  lineSpace: number;
  receiptWidth: number;
  logoScale: number;
}

@Injectable({
  providedIn: 'root'
})
export class PrinterService {
  protocol = 'https://';
  printerIPAddress = '0.0.0.0';
  endpoint = '/StarWebPRNT/SendMessage';

  builder = new StarWebPrintBuilder();
  trader = new StarWebPrintTrader({ url: this.protocol + this.printerIPAddress + this.endpoint });

  paperWidth: 'inch2' | 'inch3' | 'inch4' = 'inch3';
  canvasID = 'canvasPaper';
  canvasData: ICanvasData;

  // Configuration for different paper sizes
  paperConfigs = {
    inch2: {
      width: 384,
      height: 555,
      canvasData: {
        fontSize: 28, // Consider increasing if needed
        lineSpace: 24, // Changed from 28 to 24 (valid)
        receiptWidth: 384,
        logoScale: 1
      }
    },
    inch3: {
      width: 576,
      height: 640,
      canvasData: {
        fontSize: 48, // Increased font size for better legibility
        lineSpace: 32, // Changed from 48 to 32 (valid)
        receiptWidth: 576,
        logoScale: 1.5
      }
    },
    inch4: {
      width: 832,
      height: 952,
      canvasData: {
        fontSize: 48, // Likely sufficient
        lineSpace: 32, // Changed from 48 to 32 (valid)
        receiptWidth: 832,
        logoScale: 2
      }
    }
  };

  private logoURL: string;
  private bottomImageURL: string;

  constructor(
    private printerDevice: PrinterDeviceService,
    private toast: ToastrService,
    private directus: DirectusApiService
  ) {
    const storedAddress = this.printerDevice.getStoredPrinterIPAddress();
    if (storedAddress) {
      this.printerIPAddress = storedAddress;
      this.trader = new StarWebPrintTrader({ url: this.protocol + this.printerIPAddress + this.endpoint });
    }
    this.canvasData = this.paperConfigs[this.paperWidth].canvasData;
    this.loadCMSData();
  }

  private async loadCMSData() {
    // Load the CMS data
    const cmsData = await firstValueFrom(this.directus.getSingleItem('kiosk_content', 1));
    if ((cmsData as KioskContent).top_image) {
      this.logoURL = `${environment.cmsDomain}assets/${(cmsData as KioskContent).top_image}`;
    }
    if ((cmsData as KioskContent).bottom_image) {
      this.bottomImageURL = `${environment.cmsDomain}assets/${(cmsData as KioskContent).bottom_image}`;
    }
  }

  /**
   * Method to set/update the printer's IP address.
   * @param ipAddress The IP address of the printer.
   */
  setPrinterAddress(ipAddress: string) {
    this.printerDevice.updateStoredPrinterIPAddress(ipAddress);
    this.printerIPAddress = ipAddress;
    this.trader = new StarWebPrintTrader({ url: this.protocol + this.printerIPAddress + this.endpoint });
  }

  /**
   * Current accumulated print request.
   */
  private currentPrintRequest: string = '';

  /**
   * Method to accumulate print requests.
   * @param requestPart The part of the print request to accumulate.
   */
  private accumulatePrintRequest(requestPart: string) {
    this.currentPrintRequest += requestPart;
  }

  /**
   * Method to send a print job.
   * @param makeReceipt Function that builds the receipt content using drawAlignedText and drawLineSpace.
   */
  async onSendMessageCanvas(makeReceipt: () => void) {
    try {
      await this.loadCMSData();
      // Initialize the print request
      this.currentPrintRequest = '';
      this.currentPrintRequest += this.builder.createInitializationElement({ reset: false, print: false });
      if (this.logoURL) {
        await this.printLogo(this.logoURL);
      }

      // Build the receipt content
      makeReceipt(); // This should internally call drawAlignedText and drawLineSpace

      if (this.bottomImageURL) {
        this.drawLineSpace(3);
        await this.printBottomImage(this.bottomImageURL);
      }
      // Finalize and send the print request
      this.executePrint();
    } catch (error) {
      console.error('Exception occurred during printing:', error.message);
      this.toast.error('Failed to print receipt.');
    }
  }

  /**
   * Method to execute the accumulated print request.
   * This adds a paper cut command and sends the request to the printer.
   */
  private executePrint() {
    try {
      // Add a paper cut at the end
      this.currentPrintRequest += this.builder.createCutPaperElement({ feed: true, type: 'full' });

      // Send the accumulated print request
      this.trader.sendMessage({ request: this.currentPrintRequest });

      // Reset the print request for future jobs
      this.currentPrintRequest = '';
    } catch (error) {
      console.error('Exception occurred during executePrint:', error.message);
      this.toast.error('Failed to execute print.');
    }
  }

  /**
   * Method to print aligned text using createTextElement.
   * @param align The text alignment: 'left', 'center', 'right'.
   * @param text The text to print.
   */
  drawAlignedText(align: 'left' | 'center' | 'right', text: string) {
    try {
      let textRequest = '';

      // Set text alignment
      textRequest += this.builder.createAlignmentElement({ position: align });

      // Create text element with desired settings
      textRequest += this.builder.createTextElement({
        codepage: 'cp998', // Adjust based on your printer's codepage
        international: 'usa', // Adjust based on your region
        characterspace: 0, // Adjust if needed
        emphasis: false, // Set to true if bold is desired
        invert: false, // Set to true for inverse printing
        linespace: this.canvasData.lineSpace, // Validated linespace value (24 or 32)
        width: 1, // No width expansion
        height: 1, // No height expansion
        font: 'font_a', // 'font_a' or 'font_b' based on your preference
        undelline: false, // Set to true if underline is desired
        binary: false, // Use UTF encoding
        data: text // The actual text to print
      });

      // Accumulate the text request
      this.accumulatePrintRequest(textRequest);
    } catch (error) {
      console.error('Error in drawAlignedText:', error);
      this.toast.error('Failed to add aligned text.');
    }
  }

  /**
   * Method to add line spacing using createFeedElement.
   * @param count Number of lines to feed.
   */
  drawLineSpace(count: number = 1) {
    try {
      let feedRequest = '';

      // Add feed lines
      feedRequest += this.builder.createFeedElement({ line: count, unit: this.canvasData.lineSpace }); // 8 dots per unit

      // Accumulate the feed request
      this.accumulatePrintRequest(feedRequest);
    } catch (error) {
      console.error('Error in drawLineSpace:', error);
      this.toast.error('Failed to add line space.');
    }
  }

  /**
   * Method to print an image using createBitImageElement.
   * @param canvas The HTML5 canvas element containing the image.
   * @param x The x-coordinate to start the image.
   * @param y The y-coordinate to start the image.
   * @param width The width of the image in dots.
   * @param height The height of the image in dots.
   */
  printImage(canvas: HTMLCanvasElement, x: number = 0, y: number = 0, width: number = 200, height: number = 100) {
    try {
      let imageRequest = '';

      // Create BitImageElement
      imageRequest += this.builder.createBitImageElement({
        context: canvas.getContext('2d')!,
        x: x,
        y: y,
        width: width,
        height: height
      });

      // Add feed after image if needed
      imageRequest += this.builder.createFeedElement({ line: 1, unit: 8 });

      // Accumulate the image request
      this.accumulatePrintRequest(imageRequest);
    } catch (error) {
      console.error('Error in printImage:', error);
      this.toast.error('Failed to add image.');
    }
  }

  /**
   * Example method to build the receipt.
   * This should be replaced with your actual receipt building logic.
   */
  getWYSIWYGReceipt() {
    // Example receipt content
    this.drawAlignedText('center', 'Thank You!');
    this.drawLineSpace(2); // Add two lines of space
    this.drawAlignedText('left', 'Item 1 - $10.00');
    this.drawAlignedText('left', 'Item 2 - $20.00');
    this.drawAlignedText('right', 'Total - $30.00');
  }

  /**
   * Method to print the logo using createBitImageElement.
   * Mimics object-fit: contain by ensuring the logo fits within the container while maintaining aspect ratio.
   * @param logoUrl The URL of the logo image.
   */
  async printLogo(logoUrl: string) {
    try {
      const maxLogoWidth = this.canvasData.receiptWidth; // 50% of receipt width
      const maxLogoHeight = 200; // Fixed height in dots; adjust as needed

      // Load the logo image
      const logoImage = await this.loadImage(logoUrl);

      // Calculate aspect ratios
      const imageAspectRatio = logoImage.width / logoImage.height;
      const containerAspectRatio = maxLogoWidth / maxLogoHeight;

      let logoWidth: number;
      let logoHeight: number;

      if (imageAspectRatio > containerAspectRatio) {
        // Image is wider relative to the container
        logoWidth = maxLogoWidth;
        logoHeight = maxLogoWidth / imageAspectRatio;
      } else {
        // Image is taller relative to the container
        logoHeight = maxLogoHeight;
        logoWidth = maxLogoHeight * imageAspectRatio;
      }

      // Create an offscreen canvas to draw the logo
      const offscreenCanvas = this.createOffscreenCanvas(maxLogoWidth, maxLogoHeight);
      const offscreenContext = offscreenCanvas.getContext('2d');

      if (!offscreenContext) {
        throw new Error('Failed to get 2D context from offscreen canvas.');
      }

      // Calculate positions to center the logo within the container
      const xPosition = (maxLogoWidth - logoWidth) / 2;
      const yPosition = (maxLogoHeight - logoHeight) / 2;

      // Draw the logo image onto the offscreen canvas
      offscreenContext.drawImage(logoImage, xPosition, yPosition, logoWidth, logoHeight);

      // Create BitImageElement with the Base64 image data
      const imageRequest = this.builder.createBitImageElement({
        context: offscreenContext,
        x: 0, // Starting x position on the receipt
        y: 0, // Starting y position on the receipt
        width: maxLogoWidth, // Width of the container
        height: maxLogoHeight // Height of the container
      });

      // Accumulate the image request for printing
      this.accumulatePrintRequest(imageRequest);

      // Optionally, add a line feed after the logo for spacing
      this.drawLineSpace(1);
    } catch (error) {
      console.error('Error in printLogo:', error);
      this.toast.error('Failed to print logo.');
    }
  }

  /**
   * Method to print the logo using createBitImageElement.
   * Mimics object-fit: contain by ensuring the logo fits within the container while maintaining aspect ratio.
   * @param logoUrl The URL of the logo image.
   */
  async printBottomImage(logoUrl: string) {
    try {
      const maxLogoWidth = this.canvasData.receiptWidth; // 50% of receipt width
      const maxLogoHeight = maxLogoWidth / 2; // Fixed height in dots; adjust as needed

      // Load the logo image
      const logoImage = await this.loadImage(logoUrl);

      // Calculate aspect ratios
      const imageAspectRatio = logoImage.width / logoImage.height;
      const containerAspectRatio = maxLogoWidth / maxLogoHeight;

      let logoWidth: number;
      let logoHeight: number;

      if (imageAspectRatio > containerAspectRatio) {
        // Image is wider relative to the container
        logoWidth = maxLogoWidth;
        logoHeight = maxLogoWidth / imageAspectRatio;
      } else {
        // Image is taller relative to the container
        logoHeight = maxLogoHeight;
        logoWidth = maxLogoHeight * imageAspectRatio;
      }

      // Create an offscreen canvas to draw the logo
      const offscreenCanvas = this.createOffscreenCanvas(maxLogoWidth, maxLogoHeight);
      const offscreenContext = offscreenCanvas.getContext('2d');

      if (!offscreenContext) {
        throw new Error('Failed to get 2D context from offscreen canvas.');
      }

      // Calculate positions to center the logo within the container
      const xPosition = (maxLogoWidth - logoWidth) / 2;
      const yPosition = (maxLogoHeight - logoHeight) / 2;

      // Draw the logo image onto the offscreen canvas
      offscreenContext.drawImage(logoImage, xPosition, yPosition, logoWidth, logoHeight);

      const imageRequest = this.builder.createBitImageElement({
        context: offscreenContext,
        x: 0, // Starting x position on the receipt
        y: 0, // Starting y position on the receipt
        width: maxLogoWidth, // Width of the container
        height: maxLogoHeight // Height of the container
      });

      // Accumulate the image request for printing
      this.accumulatePrintRequest(imageRequest);

      // Optionally, add a line feed after the logo for spacing
      this.drawLineSpace(1);
    } catch (error) {
      console.error('Error in printLogo:', error);
      this.toast.error('Failed to print logo.');
    }
  }

  /**
   * Method to draw two strings, one left-aligned and one right-aligned on the same line.
   * The function calculates the number of spaces needed to push the second string to the right.
   * @param leftText The string to be left-aligned.
   * @param rightText The string to be right-aligned.
   */
  drawLeftRightText(leftText: string, rightText: string) {
    try {
      const totalWidthInChars = 48; // Approximate number of characters that fit in the receipt width
      const combinedTextLength = leftText.length + rightText.length;

      // Calculate how many spaces are needed between leftText and rightText
      const spacesInBetween = totalWidthInChars - combinedTextLength;

      if (spacesInBetween < 0) {
        // If the text is too long to fit on one line, just print the left and right text separately
        this.drawAlignedText('left', leftText);
        this.drawAlignedText('right', rightText);
        return;
      }

      // Create the combined text with spaces in between
      const combinedText = leftText + ' '.repeat(spacesInBetween) + rightText;

      // Add the combined text as a single text element
      this.drawAlignedText('left', combinedText); // Printing as a single left-aligned element
    } catch (error) {
      console.error('Error in drawLeftRightText:', error);
      this.toast.error('Failed to add left-right aligned text.');
    }
  }

  /**
   * Helper method to load an image from a URL.
   * @param url The URL of the image to load.
   * @returns A Promise that resolves to the loaded HTMLImageElement.
   */
  private loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Handle CORS if needed
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = err => reject(new Error(`Failed to load image from ${url}`));
    });
  }

  /**
   * Helper function to create an offscreen canvas.
   * @param width The width of the offscreen canvas.
   * @param height The height of the offscreen canvas.
   * @returns The created offscreen canvas.
   */
  private createOffscreenCanvas(width: number, height: number): HTMLCanvasElement {
    const offscreen = document.createElement('canvas');
    offscreen.width = width;
    offscreen.height = height;
    return offscreen;
  }
}
