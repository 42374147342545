import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BasketService } from '@app/providers/basket.service';
import { CmsService } from '@app/providers/cms.service';
import { ModeService } from '@app/providers/mode.service';
import { CapacityService } from '@app/providers/capacity.service';
import { SetBanner } from '@app/store/actions/app.action';
import {
  GetBasket,
  GetTimeSlots,
  RemoveProductFromBasket,
  SetBillingInfo,
  SetBuckets,
  SetTimeWanted,
  ValidateBasket
} from '@app/store/actions/basket.action';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { Basket, BasketValidation } from '../../../../assets/chepri-modules/src/models/olo.basket';
import { Restaurant } from '../../../../assets/chepri-modules/src/models/olo.restaurant';
import { TimeRange } from '../../../../assets/chepri-modules/src/models/olo.calendars';
import { SelectableSpread, TimeSlot } from '@app/models/capacity.model';
import { SetDineInName, SetPartySize } from '@app/store/actions/user.action';
import { DineInName } from '../../../../assets/chepri-modules/src/models/dineinname';
import { BillingInfo } from '../../../../assets/chepri-modules/src/models/olo.billinginfo';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { BasketStateModel } from '@app/store/state/basket.state';
import { BasketProduct } from '../../../../assets/chepri-modules/src/models/olo.basketproduct';
import { environment } from '@env/environment';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['../customer-kiosk.component.scss']
})
export class CustomerKioskOrderSummaryComponent implements OnInit, OnDestroy {
  get firstName(): AbstractControl {
    return this.infoForm.get('first');
  }
  get lastName(): AbstractControl {
    return this.infoForm.get('last');
  }
  get email(): AbstractControl {
    return this.infoForm.get('email');
  }
  @Select((state: any) => state.basket.basket) basket$: Observable<Basket>;
  @Select((state: any) => state.basket.timeSlots) timeSlots$: Observable<TimeSlot[]>;

  maxTableSize = 8;
  tableSizeChoicesArray: number[] = [];
  selectedPartySize = 0;
  isLoading = false;
  isAsap = true;
  isRemoveLoading = false;

  infoForm = new UntypedFormGroup({
    first: new UntypedFormControl('', [Validators.required]),
    last: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.email])
  });

  timeSelect = new FormControl('', [Validators.required]);

  private subs: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private basketService: BasketService,
    private cms: CmsService,
    private modeService: ModeService,
    private capacityService: CapacityService
  ) {}

  ngOnInit(): void {
    this.updateTableSizeArray(this.maxTableSize);
    this.store.dispatch(new SetBanner('ORDER SUMMARY', true, true, ''));
    this.store.dispatch(new GetBasket()).subscribe(() => {
      this.store.dispatch(new ValidateBasket()).subscribe(
        () => {},
        error => {
          this.toastr.warning(error.error.message);
        }
      );
    });
    this.store
      .selectOnce(state => state.restaurant.restaurant)
      .subscribe((res: Restaurant) => {
        this.cms.getLocation(String(res.id)).subscribe(cmsLoc => {
          this.maxTableSize = Number(cmsLoc.max_table_size);
          this.updateTableSizeArray(this.maxTableSize);
        });
      });
    this.newTimeClicked('asap');
    this.subs.push(
      this.basket$.subscribe(basket => {
        if (basket.products.length < 1) {
          this.router.navigate(['cks', 'menu', basket.vendorid]);
        }
      })
    );
    this.setExistingNameandTableCount();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  setUserInfo() {
    if (this.selectedPartySize) {
      this.store.dispatch(new SetPartySize(this.selectedPartySize));
    }
    if (this.firstName.value && this.lastName.value) {
      const dineInName: DineInName = {
        firstname: this.firstName.value,
        lastname: this.lastName.value,
        email: this.email.value
      };
      this.store.dispatch(new SetDineInName(dineInName));
    }
  }

  setExistingNameandTableCount() {
    this.store
      .selectOnce(state => state.user)
      .subscribe(user => {
        if (user.partySize) {
          this.selectedPartySize = user.partySize;
        }
        if (user.dineInName) {
          this.firstName.setValue(user.dineInName.firstname);
          this.lastName.setValue(user.dineInName.lastname);
          this.email.setValue(user.dineInName.email);
        }
      });
  }

  newTimeClicked(slot: any, isRetry?: boolean) {
    if (slot === 'asap') {
      this.store.dispatch(new GetBasket()).subscribe(() => {
        this.store.dispatch(new GetTimeSlots(false)).subscribe(() => {
          this.isAsap = true;
          this.onTimeChange(0, isRetry);
        });
      });
    } else {
      this.isAsap = false;
      this.onTimeChange(slot);
    }
  }

  onTimeChange(index: number, isRetry?: boolean) {
    this.store
      .selectOnce(state => state.basket.timeSlots)
      .subscribe((timeSlots: TimeSlot[]) => {
        let selected: SelectableSpread;
        if (timeSlots && timeSlots[index] && timeSlots[index].value) {
          selected = timeSlots[index].value;
        } else {
          return;
        }
        this.store
          .selectOnce(state => state.basket.basket)
          .subscribe(basket => {
            const isManualFire = basket.deliverymode === 'dinein';
            this.store
              .dispatch(new SetTimeWanted(selected.buckets[selected.buckets.length - 1].time, isManualFire))
              .subscribe(() => {
                this.store.dispatch(new SetBuckets(selected.buckets, isManualFire));
                if (isRetry) {
                  this.continueToNextPage();
                }
              });
          });
      });
  }

  continueToNextPage() {
    this.setUserInfo();
    this.isLoading = true;
    if (!(this.firstName.valid && this.lastName.valid)) {
      this.isLoading = false;
      this.toastr.warning('Please enter first name and last initial.');
      return;
    } else {
      this.store
        .selectOnce(state => state.basket.basket)
        .subscribe((basket: Basket) => {
          if (basket.deliverymode === 'dinein' && !this.selectedPartySize) {
            this.toastr.warning('Please select your party size.');
            this.isLoading = false;
            return;
          }
          this.store.dispatch(new GetBasket()).subscribe(() => {
            this.store.dispatch(new ValidateBasket()).subscribe(
              () => {
                if (this.selectedPartySize) {
                  this.store.dispatch(new SetPartySize(this.selectedPartySize));
                }
                const dineInName: DineInName = {
                  firstname: this.firstName.value,
                  lastname: this.lastName.value,
                  email: this.email.value
                };
                this.store.dispatch(new SetDineInName(dineInName));
                const billingInfo = new BillingInfo();
                billingInfo.firstname = this.firstName.value;
                billingInfo.lastname = this.lastName.value;
                billingInfo.emailaddress = this.email.value ? this.email.value : environment.kioskDefaultEmail;
                localStorage.setItem('emailAddress', this.email.value);
                billingInfo.contactnumber = environment.kioskDefaultPhone;
                billingInfo.usertype = 'guest';
                this.store.dispatch(new SetBillingInfo(billingInfo));
                forkJoin({
                  basketState: this.store.selectOnce(state => state.basket),
                  restaurantState: this.store.selectOnce(state => state.restaurant)
                }).subscribe(states => {
                  const restaurantId = (states.restaurantState as RestaurantStateModel).restaurant.id;
                  const capacitySettings = (states.restaurantState as RestaurantStateModel).capacity_settings;
                  const buckets = (states.basketState as BasketStateModel).buckets;
                  const currentBucketIds = states.basketState.capacityPlaceholder;
                  const products = (states.basketState as BasketStateModel).basket.products;
                  const checkBuzzCapacity = false;
                  this.capacityService
                    .checkLastSecond(
                      capacitySettings,
                      restaurantId,
                      buckets,
                      currentBucketIds,
                      products,
                      checkBuzzCapacity
                    )
                    .subscribe(lastSecond => {
                      if (lastSecond.canFit) {
                        this.router.navigate(['cks', 'checkout']);
                        this.isLoading = false;
                      } else {
                        if (!lastSecond.enzoError && this.isAsap) {
                          this.store.dispatch(new GetTimeSlots(false)).subscribe(res => {
                            if (res.basket.timeSlots && res.basket.timeSlots.length) {
                              this.onTimeChange(0, true);
                            } else {
                              this.isLoading = false;
                              this.toastr.warning('We are currently sold out.');
                            }
                          });
                        } else {
                          this.isLoading = false;
                          this.toastr.warning(
                            lastSecond.enzoError
                              ? 'Sorry, Gluten-Free is sold out. To proceed with your order please remove Gluten-Free from your basket.'
                              : 'The order time selected is no longer available, please choose a different time'
                          );
                        }
                      }
                    });
                });
              },
              error => {
                this.isLoading = false;
                if (error.error.num === 206 || error.error.message.toLowerCase().includes('select a later time')) {
                  this.newTimeClicked('asap', true);
                } else {
                  this.toastr.warning(error.error.message);
                }
              }
            );
          });
        });
    }
  }

  removeProduct(product: BasketProduct) {
    this.isRemoveLoading = true;
    this.store.dispatch(new RemoveProductFromBasket(String(product.id))).subscribe(() => {
      this.isRemoveLoading = false;
      this.store.dispatch(new GetTimeSlots(false));
    });
  }

  editProduct(productID: number, id: number): void {
    this.router.navigate(['cks', 'product', productID], { queryParams: { edit: true, basketProd: id } });
  }

  private updateTableSizeArray(maxSize: number) {
    this.tableSizeChoicesArray = Array.from({ length: maxSize }, (_, i) => i + 1);
  }
}
