import { Injectable } from '@angular/core';
import {
  CapacityInterval,
  DirectusCapacitySettings,
  DirectusCurrentOrder,
  SelectableTime,
  TimeSlot,
  SelectableSpread,
  DirectusLocation
} from '@app/models/capacity.model';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { orderstatus } from 'src/assets/chepri-modules/src/models/olo.recentorders';
import { Limiters } from 'src/assets/chepri-modules/src/models/Limiter';
import { DateTime, DateTimeService } from 'src/assets/chepri-modules/src/models/DateTime';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { BasketProduct } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { Cacheable } from 'ts-cacheable';
import { DirectusHttpService } from '@app/providers/expo/directus/directus-http.service';
import { CmsService } from '@app/providers/cms.service';
import { Locations } from '@app/models/directus.collections';
import { environment } from '@env/environment';

const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const pizzaCatId = 100;
const saladCatId = 110;
const minuteIncrement = 5;
const maxBucketSpread = 5;

@Injectable({
  providedIn: 'root'
})
export class CapacityService {
  domainAPI = environment.cmsDomain;

  constructor(
    private http: HttpClient,
    private dateTime: DateTimeService,
    private store: Store,
    private directusHTTP: DirectusHttpService,
    private cms: CmsService
  ) {}

  /**
   * Gets a list of time buckets and their current capacity
   * @param capacitySettings restaurant capacity settings from `restaurant.state`
   * @param end closing time from `restaurant.state.calendar` - HH:mm:ss format
   * @param orders array of orders for that restaurant
   * @returns An array of all buckets for the reset of the day and their pizza / salad capacity
   */
  getHeatmapData(capacitySettings: Locations, end: any, orders: DirectusCurrentOrder[]): SelectableTime[] {
    const restaurant = this.store.selectSnapshot(RestaurantState).restaurant;
    const dailyCapacity = this.getDailyCapacity(capacitySettings, weekdays[moment().weekday()]);
    const availabilityArr = this.removeBeforeOpenAfterClosed(
      this.getAvailabilityArray(dailyCapacity, orders, false),
      DateTime.fromDirectus(
        moment()
          .utcOffset(restaurant.utcoffset)
          .format()
      ),
      DateTime.fromDirectus(end.toString())
    );
    return availabilityArr;
  }

  /**
   * Gets a array of buckets that can fit 1 to 6 pizza
   * @param capacitySettings restaurant capacity settings from `restaurant.state`
   * @param end closing time from `restaurant.state.calendar` - HH:mm:ss format
   * @param orders array of orders for that restaurant
   * @param checkBuzzCapacity boolean to check if the order is a buzz order
   * @returns An array of the next slots that can fit 1-6 pizzas
   */
  getNextAvailableTimes(
    capacitySettings: Locations,
    end: any,
    orders: DirectusCurrentOrder[],
    checkBuzzCapacity: boolean
  ): TimeSlot[] {
    const restaurant = this.store.selectSnapshot(RestaurantState).restaurant;
    const dailyCapacity = this.getDailyCapacity(capacitySettings, weekdays[moment().weekday()]);
    // Initialize timeSlots array
    let timeSlots: any[] = [];
    // Return the array as it updates
    for (let i = 0; i < 6; i++) {
      const availabilityArr = this.removeBeforeOpenAfterClosed(
        this.getAvailabilityArray(dailyCapacity, orders, checkBuzzCapacity),
        DateTime.fromDirectus(
          moment()
            .utcOffset(restaurant.utcoffset)
            .add(7 + i, 'm')
            .startOf('minute')
            .format()
        ),
        DateTime.fromDirectus(end.toString())
      );
      for (const [index, bucket] of availabilityArr.entries()) {
        const pizzaQuant = i + 1;
        const saladQuant = 0;
        timeSlots = Object.assign([], timeSlots); // Required for push/pop operations to be permitted

        if (bucket.canFit(pizzaQuant, saladQuant)) {
          timeSlots.push(this.generateSlot(bucket.time, pizzaQuant, saladQuant));
        } else {
          // Attempt to get a spread
          const slice = availabilityArr.slice(index, index + maxBucketSpread); // Get slice of intervals starting from current interval to current + maxSpread
          const spread = this.attemptLookAhead(slice, pizzaQuant, saladQuant);
          if (spread.buckets.length > 0) {
            timeSlots.push(this.generateSlotFromSpread(spread));
          }
        }
        if (availabilityArr.length === index + 1 && !timeSlots[i]) {
          timeSlots.push(null);
        }
        if (timeSlots[i]) {
          break;
        }
      }
    }
    return timeSlots;
  }

  /**
   *  Returns DailyCapacity which has intervals, an array of capacity intervals for the current day
   *  @param capacitySettings restaurant capacity settings from `restaurant.state`
   *  @param weekday 'sunday', 'monday', 'tuesday', etc.
   */
  getDailyCapacity(capacitySettings: Locations, weekday: string): CapacityInterval[] {
    const intervals: CapacityInterval[] = [];
    if (capacitySettings.capacity_days) {
      capacitySettings.capacity_days.forEach(day => {
        const interval = day;
        intervals.push({
          start_time: interval.start_time,
          end_time: interval.end_time,
          capacity: interval[weekday],
          buzz_capacity: interval[weekday + '_buzz']
        });
      });
    }
    return intervals;
  }

  /**
   * Filters the orders by ready time
   * @param orders array of orders to filter
   * @param time bucket end time / order ready time to filter (HH:mm:ss)
   */
  filterOrdersByTime(orders: DirectusCurrentOrder[], time: string): DirectusCurrentOrder[] {
    return orders.filter(o => this.dateTime.compareDirectusToDirectusTime(o.ready_time, time) === 0);
  }

  /**
   * Returns an Availability object which represents the amount of pizzas and salads
   * that the given interval can accept
   * @param cap Capacity value for the given interval
   * @param orders Order objects that are within the given interval
   */
  getAvailability(
    interval: CapacityInterval,
    orders: DirectusCurrentOrder[],
    checkBuzzCapacity: boolean,
    currentBucketIds?: string[]
  ): SelectableTime {
    let numOfPizzas = orders.reduce((a, b) => a + b.pizza_quant, 0);
    let numOfSalads = orders.reduce((a, b) => a + b.salad_quant, 0);
    let takeoutPizzas = orders.reduce((a, b) => (b.is_olo_takeout ? a + b.pizza_quant : a), 0);
    let takeoutSalads = orders.reduce((a, b) => (b.is_olo_takeout ? a + b.salad_quant : a), 0);
    if (currentBucketIds && currentBucketIds.length) {
      orders.forEach((o: DirectusCurrentOrder) => {
        if (currentBucketIds.find(id => id === o.id.toString())) {
          numOfPizzas = numOfPizzas - o.pizza_quant;
          numOfSalads = numOfSalads - o.salad_quant;
          if (o.is_olo_takeout) {
            takeoutPizzas = takeoutPizzas - o.pizza_quant;
            takeoutSalads = takeoutSalads - o.salad_quant;
          }
        }
      });
    }
    let remainingPizzas = interval.capacity - numOfPizzas;
    let remainingSalads = interval.capacity - numOfSalads;
    if (checkBuzzCapacity) {
      const allowedBuzz = Math.ceil(interval.capacity * (interval.buzz_capacity / 100));
      const remainingPizzaBuzz = allowedBuzz - takeoutPizzas;
      const remainingSaladBuzz = allowedBuzz - takeoutSalads;
      remainingPizzas = Math.max(remainingPizzas < remainingPizzaBuzz ? remainingPizzas : remainingPizzaBuzz, 0);
      remainingSalads = Math.max(remainingSalads < remainingSaladBuzz ? remainingSalads : remainingSaladBuzz, 0);
    }
    return new SelectableTime(
      DateTime.fromDirectusTime(interval.end_time),
      Math.max(remainingPizzas, 0),
      Math.max(remainingSalads, 0)
    );
  }

  /**
   * Builds availability array based on today's capacity settings and the orders already placed
   * @param dailyCapacity Array of capacity intervals specified in location's capacity settings
   * @param DirectusCurrentOrders Array of current orders to compare against
   */
  getAvailabilityArray(
    dailyCapacity: CapacityInterval[],
    currentOrders: DirectusCurrentOrder[],
    checkBuzzCapacity: boolean,
    currentBucketIds?: string[]
  ): SelectableTime[] {
    return dailyCapacity.map(interval =>
      this.getAvailability(
        interval,
        this.filterOrdersByTime(currentOrders, interval.end_time),
        checkBuzzCapacity,
        currentBucketIds
      )
    );
  }

  /**
   * Takes array of current availability buckets and filters out buckets before the start time and after the end time
   * @param availabilityArr Array of SelectableTime representing the current availability, given current orders
   * @param start Beginning of filter (time of opening when store is not yet open, otherwise current time)
   * @param end End of filter (closing time of restaurant)
   */
  removeBeforeOpenAfterClosed(availabilityArr: SelectableTime[], start: DateTime, end: DateTime): SelectableTime[] {
    return availabilityArr.filter(s => s.time.compare(start) >= 0 && s.time.compare(end) <= 0);
  }

  /**
   * Returns copy of timeSlots excluding the second most recent addition if the two most recent additions have matching keys
   * @param timeSlots array to filter
   */
  filterRecentDuplicates(timeSlots: TimeSlot[]): TimeSlot[] {
    if (timeSlots.length < 2) {
      return timeSlots;
    }
    const timeSlotsCopy = [...timeSlots];
    const newTimeSlot = timeSlotsCopy.pop();
    const oldTimeSlot = timeSlotsCopy.pop();
    if (oldTimeSlot.key !== newTimeSlot.key) {
      timeSlotsCopy.push(oldTimeSlot);
    }
    timeSlotsCopy.push(newTimeSlot);
    return timeSlotsCopy;
  }

  getOrdersThenTimeSlots() {}

  /**
   * Gets a list of times as key, value pairs that are available for the current order
   * @param capacitySettings restaurant capacity settings from `restaurant.state`
   * @param leadTime leadtimeestimateminutes from `basket.state`
   * @param end closing time from `restaurant.state.calendar` - HH:mm:ss format
   * @param currentOrders List of orders currently open today - from `restaurant.state.orders`
   * @param limiters Current list of number of pizzas and salads - from `basket.state.capacity`
   * @returns An array of key, value pairs, the key being a formatted time, and
   * the value being a time value Olo understands
   */
  getAvailableTimeSlots(
    capacitySettings: Locations,
    earliestRT: string, // Olo formatted DateTime
    end: string,
    vendorId: string,
    limiters: Limiters,
    isFirstLoad: boolean,
    checkBuzzCapacity: boolean
  ): Observable<TimeSlot[]> {
    // Setup variables
    const startTimeDT = DateTime.fromOlo(earliestRT); // Default to current time
    // TODO: Check if before store opens, and if so, use hour of opening instead
    const endTimeDT = DateTime.fromDirectus(end);
    let pizzaQuant = this.getPizzaQuant(limiters);
    if (isFirstLoad && pizzaQuant === 0) {
      pizzaQuant = 1;
    }
    const saladQuant = this.getSaladQuant(limiters);
    const dailyCapacity = this.getDailyCapacity(capacitySettings, weekdays[moment().weekday()]);
    return this.getOrders(vendorId, false).pipe(
      map((orders: DirectusCurrentOrder[]) => {
        const availabilityArr = this.removeBeforeOpenAfterClosed(
          this.getAvailabilityArray(dailyCapacity, orders, checkBuzzCapacity),
          startTimeDT,
          endTimeDT
        );
        // Initialize timeSlots array
        let timeSlots: TimeSlot[] = [];

        // Return the array as it updates
        availabilityArr.forEach((bucket, index) => {
          timeSlots = Object.assign([], timeSlots); // Required for push/pop operations to be permitted
          if (bucket.canFit(pizzaQuant, saladQuant)) {
            timeSlots.push(this.generateSlot(bucket.time, pizzaQuant, saladQuant));
          } else {
            // Attempt to get a spread
            const slice = availabilityArr.slice(index, index + maxBucketSpread); // Get slice of intervals starting from current interval to current + maxSpread
            const spread = this.attemptLookAhead(slice, pizzaQuant, saladQuant);
            if (spread.buckets.length > 0) {
              timeSlots.push(this.generateSlotFromSpread(spread));
            }
          }
          timeSlots = this.filterRecentDuplicates(timeSlots);
        });
        return timeSlots;
      })
    );
  }

  /**
   * Returns a window of the orders specified that take place between `start` and `end`
   * @param orders array of the orders to filter from
   * @param start interval start time: beginning of the window returned
   * @param end interval end time: the end of the window returned
   */
  getOrdersInInterval(orders: DirectusCurrentOrder[], start: DateTime, end: DateTime): DirectusCurrentOrder[] {
    return orders.filter(order => {
      return DateTime.fromDirectus(order.ready_time).moment.isBetween(start.moment, end.moment, null, '[]');
    });
  }

  /**
   * Generates a single key, value pair for the timepicker
   * @param time Time to generate slot for, e.g. interval.end_time
   * @param lead Any additional maketime to add to the order, e.g. Olo's leadtimeestimateminutes
   */
  generateSlot(dateTime: DateTime, pizzas: number, salads: number): TimeSlot {
    return {
      key: dateTime.time,
      value: new SelectableSpread([new SelectableTime(dateTime, pizzas, salads)])
    };
  }

  /**
   * Returns a spread of where to assign the orders if they will fit, otherwise returns an empty array
   * @param orders The existing orders to check availability against
   * @param intervals The subset of intervals to attempt to spread the order across
   * @param pizzas The number of pizzas in the order
   * @param salads The number of salads in the order
   */
  attemptLookAhead(intervals: SelectableTime[], pizzas: number, salads: number): SelectableSpread {
    let pizzaRemaining = pizzas;
    let saladRemaining = salads;
    let index = 0;
    const buckets: SelectableTime[] = [];
    while ((pizzaRemaining > 0 || saladRemaining > 0) && index < intervals.length) {
      // while buckets remain and more items need assignments
      let pizzaToAdd = 0;
      let saladToAdd = 0;
      // If pizzas remain, add as many as possible
      if (pizzaRemaining > 0) {
        if (intervals[index].pizzas >= pizzaRemaining) {
          pizzaToAdd = pizzaRemaining; // If availability >= remaining, set pizzaToAdd to remaining
        } else {
          pizzaToAdd = intervals[index].pizzas; // If availability < remaining, set pizzaToAdd to availability
        }
        pizzaRemaining -= pizzaToAdd; // Subtract pizzaToAdd from pizzaRemaining
      }
      // If salads remain, add as many as possible
      if (saladRemaining > 0) {
        if (intervals[index].salads >= saladRemaining) {
          saladToAdd = saladRemaining; // If availability >= remaining, set saladToAdd to remaining
        } else {
          saladToAdd = intervals[index].salads; // If availability < remaining, set saladToAdd to availability
        }
        saladRemaining -= saladToAdd; // Subtract saladToAdd from saladRemaining
      }
      // Push a new selectable time to the buckets array, increment index
      buckets.push(new SelectableTime(intervals[index].time, pizzaToAdd, saladToAdd));
      index++;
    }
    // If pizzas or salads remain, attempt failed, return empty array
    if (pizzaRemaining > 0 || saladRemaining > 0) {
      return new SelectableSpread([]);
    } else {
      return new SelectableSpread(buckets);
    }
  }

  generateSlotFromSpread(spread: SelectableSpread): TimeSlot {
    return {
      key: spread.buckets[spread.buckets.length - 1].time.time,
      value: spread
    };
  }

  /**
   * Creates metadata entries in Directus for the various chunks in a spread
   * @param vendorId Id of the location ordered from
   * @param oloId a.k.a. order id
   * @param spread spread from which to create metadata entries
   */
  createOrUpdateOrderMetaDataFromBuckets(
    vendorId: number,
    oloId: string,
    buckets: SelectableTime[],
    isPlaceholder: boolean,
    enzo_quant: number,
    isOloTakeout: boolean,
    itemId?: string[]
  ): Observable<any> {
    const restaurant = this.store.selectSnapshot(RestaurantState).restaurant;
    return this.cms.getLocation(vendorId.toString()).pipe(
      switchMap(location => {
        const location_id = location.id;
        if (itemId && itemId.length) {
          return combineLatest(
            itemId.map(item => {
              return this.updateOrderMetaDataItem(item);
            })
          ).pipe(map(res => res));
        } else {
          return combineLatest(
            buckets.map((b: any, i: number) => {
              let directusTime = b.time.directusDateTime;
              if (typeof b.time.moment === 'string' || b.time.moment instanceof String) {
                directusTime = moment(b.time.moment).format('YYYY-MM-DDTHH:mm:ss');
              }
              directusTime = directusTime.replace(' ', 'T');
              return this.createOrderMetaDataItem(
                oloId,
                location_id,
                directusTime,
                b.salads,
                b.pizzas,
                i === 0 ? enzo_quant : 0,
                isPlaceholder,
                isOloTakeout
              );
            })
          ).pipe(map(res => res));
        }
      })
    );
  }

  /**
   * Determines if the current buckets selected can fit given the location's settings and current open orders
   * @param capacitySettings Current location's capacity settings
   * @param vendorId id of current location
   * @param buckets candidate buckets for order being checked
   */
  checkLastSecond(
    capacitySettings: Locations,
    vendorId: number,
    buckets: SelectableTime[],
    currentBucketIds: string[],
    products: BasketProduct[],
    checkBuzzCapacity: boolean
  ) {
    // Assume that the new order can fit
    let canFit = true;
    let enzoError = false;
    let enzoAmount = 0;
    // Get the daily capacity for the current day
    const dailyCapacity = this.getDailyCapacity(capacitySettings, weekdays[moment().weekday()]);

    return this.getOrders(vendorId.toString(), false, buckets[0].time.directusDateTime).pipe(
      switchMap((orders: DirectusCurrentOrder[]) => {
        const availabilityArr = this.removeBeforeOpenAfterClosed(
          this.getAvailabilityArray(dailyCapacity, orders, checkBuzzCapacity, currentBucketIds),
          buckets[0].time,
          buckets[buckets.length - 1].time
        );
        buckets.forEach(bucket => {
          if (!availabilityArr.find(s => s.time.compare(bucket.time) === 0).canFit(bucket.pizzas, bucket.salads)) {
            canFit = false;
          }
        });
        const restaurant = this.store.selectSnapshot(RestaurantState);
        products.forEach(p => {
          p.choices.forEach(c => {
            if (c.name === restaurant.settings.enzo_modifier_name) {
              enzoAmount = enzoAmount + p.quantity;
            }
          });
        });
        return this.checkEnzoAvailability(vendorId, currentBucketIds).pipe(
          map(enzoAvailable => {
            if (enzoAmount > (enzoAvailable < 0 ? 0 : enzoAvailable)) {
              canFit = false;
              enzoError = true;
            }
            return {
              canFit,
              enzoError,
              currentOrders: orders
            };
          })
        );
      })
    );
  }

  /**
   * Determines if the enzo mofifier can be added to the basket
   * @param vendorId id of current location
   */
  checkEnzoAvailability(vendorId: number, currentBucketIds?: string[]) {
    const restaurant = this.store.selectSnapshot(RestaurantState).restaurant;
    let enzoLimit = 0;
    let total = 0;
    return this.cms.getLocation(vendorId.toString()).pipe(
      switchMap(loc => {
        const day = moment()
          .utcOffset(restaurant.utcoffset)
          .format('dddd')
          .toLowerCase();
        enzoLimit = loc[day];
        return this.getEnzoOrders(vendorId.toString()).pipe(
          map((orders: DirectusCurrentOrder[]) => {
            orders.forEach((o: DirectusCurrentOrder) => {
              if (currentBucketIds && currentBucketIds.length) {
                if (o.enzo_quant > 0 && !currentBucketIds.includes(o.id.toString())) {
                  total = total + o.enzo_quant;
                }
              } else {
                if (o.enzo_quant > 0) {
                  total = total + o.enzo_quant;
                }
              }
            });
            const remainingAmount = enzoLimit - total;
            return remainingAmount;
          })
        );
      })
    );
  }

  /**
   *  Creates a Directus item in the collection order_meta_data upon a successful order
   *
   *  @param oloid Order id associated with the successful order
   *  @param location id of the location item associated with the order's vendorId
   *  @param salad_quant quantity of salads in the order
   *  @param pizza_quant quantity of pizzas in the order
   */
  createOrderMetaDataItem(
    oloid: string,
    location: number,
    ready_time: Date,
    salad_quant: number,
    pizza_quant: number,
    enzo_quant: number,
    is_placeholder: boolean,
    is_olo_takeout: boolean
  ): Observable<any> {
    const body = {
      location,
      ready_time,
      salad_quant,
      pizza_quant,
      enzo_quant,
      is_placeholder,
      is_olo_takeout
    };
    return this.http.post(this.domainAPI + 'items/order_meta_data?activity_skip=1', body);
  }

  /**
   *  Updates a Directus item in the collection order_meta_data upon a successful order
   *
   *  @param oloid Order id associated with the successful order
   */
  updateOrderMetaDataItem(oloid: string): Observable<any> {
    const body = {
      is_placeholder: false
    };
    return this.http.patch(this.domainAPI + 'items/order_meta_data/' + oloid + '?activity_skip=1', body);
  }

  /**
   *  Gets the id of the location item associated with the given vendorId
   *
   *  @param vendorId unique id of the location to get Directus location
   *
   *  @returns Observable of the location item with the given vendorId
   */
  @Cacheable({
    maxAge: 120000
  })
  getLocation(vendorId: string) {
    return this.http.get(this.domainAPI + 'items/locations?filter[olo_id][_eq]=' + vendorId);
  }

  /**
   *
   * @param vendorId id of the location to get orders for
   * @param startingFrom time to start filtering from, defaults to current time (YYYY-MM-DD HH:mm:ss)
   */
  getOrders(vendorId: string, allDay: boolean, startingFrom?: string): Observable<DirectusCurrentOrder[]> {
    return this.store
      .selectOnce(state => state.restaurant.restaurant)
      .pipe(
        switchMap(restaurant => {
          startingFrom =
            startingFrom ||
            moment()
              .utcOffset(restaurant.utcoffset)
              .format('YYYY-MM-DDTHH:mm:ss');
          const readyTimeStart = allDay
            ? moment()
                .startOf('day')
                .utcOffset(restaurant.utcoffset)
                .format('YYYY-MM-DDTHH:mm:ss')
            : moment()
                .utcOffset(restaurant.utcoffset)
                .format('YYYY-MM-DDTHH:mm:ss');
          return this.cms.getLocation(vendorId).pipe(
            switchMap(location => {
              return this.http
                .get(
                  this.domainAPI +
                    'items/order_meta_data?limit=-1&filter[location][_eq]=' +
                    location.id +
                    '&filter[ready_time][_gte]=' +
                    readyTimeStart
                )
                .pipe(
                  map((res: any, index) => {
                    return this.filterOrders(res, startingFrom);
                  })
                );
            })
          );
        })
      );
  }

  /**
   *
   * @param vendorId id of the location to get enzo orders for
   * @param startingFrom time to start filtering from, defaults to current time (YYYY-MM-DD HH:mm:ss)
   */
  getEnzoOrders(vendorId: string, startingFrom?: string): Observable<DirectusCurrentOrder[]> {
    return this.store
      .selectOnce(state => state.restaurant.restaurant)
      .pipe(
        switchMap(restaurant => {
          startingFrom =
            startingFrom ||
            moment()
              .utcOffset(restaurant.utcoffset)
              .format('YYYY-MM-DDTHH:mm:ss');
          const readyTimeStart = moment()
            .startOf('day')
            .utcOffset(restaurant.utcoffset)
            .format('YYYY-MM-DDTHH:mm:ss');
          return this.cms.getLocation(vendorId).pipe(
            switchMap(location => {
              return this.http
                .get(
                  this.domainAPI +
                    'items/order_meta_data?limit=-1&filter[location][_eq]=' +
                    location.id +
                    '&filter[ready_time][_gte]=' +
                    readyTimeStart +
                    '&filter[enzo_quant][_gt]=0'
                )
                .pipe(
                  map((res: any, index) => {
                    return this.filterOrders(res, startingFrom);
                  })
                );
            })
          );
        })
      );
  }

  getOldCapacityOrders(): Observable<DirectusCurrentOrder[]> {
    const restaurant = this.store.selectSnapshot(RestaurantState).restaurant;
    const startDay = moment()
      .startOf('day')
      .utcOffset(restaurant.utcoffset)
      .format('YYYY-MM-DDTHH:mm:ss');
    return this.http.get(this.domainAPI + 'items/order_meta_data?limit=500&filter[ready_time][_lte]=' + startDay).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  deleteOldCapacityOrders(id: string): Observable<any> {
    return this.directusHTTP.delete<any>('items/order_meta_data/' + id + '?activity_skip=1');
  }

  filterOrders(orders: any, startingFrom: string): DirectusCurrentOrder[] {
    const timeLimit = this.store.selectSnapshot(RestaurantState).settings.checkout_timeout_length;
    const ordersData: DirectusCurrentOrder[] = [];
    const data: DirectusCurrentOrder[] = orders.data;
    /*
    data.filter(
      (el: DirectusCurrentOrder) =>
        DateTime.fromDirectus(el.ready_time).compare(DateTime.fromDirectus(startingFrom)) >= 0
    );
    */
    data.forEach((o: any, index: number) => {
      if (!(o.is_placeholder && this.isExpired(o.created_on, timeLimit))) {
        ordersData.push(o);
      }
    });
    return ordersData;
  }

  isExpired(createdTime: string, timeLimit: number): boolean {
    return moment().diff(moment(createdTime)) / 60000 > timeLimit ? true : false;
  }

  removeOldOrder(id: string): Observable<any> {
    return this.http.delete(this.domainAPI + 'items/order_meta_data/' + id);
  }

  /**
   * Gets the quantity of salads in the capacity state
   */
  getSaladQuant(capacity: Limiters): number {
    return this.getQuantFromLimiter(capacity, saladCatId);
  }

  /**
   * Gets the quantity of pizzas in the capacity state
   */
  getPizzaQuant(capacity: Limiters): number {
    return this.getQuantFromLimiter(capacity, pizzaCatId);
  }

  getQuantFromLimiter(capacity: Limiters, categoryId: number): number {
    if (!(capacity && capacity._items && capacity._items.length)) {
      return 0;
    }
    const category = capacity._items.find(limiter => limiter.categoryId === categoryId);
    return category && category.products ? category.products.length : 0;
  }
}
