import { Injectable } from '@angular/core';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { IDirectusExpoOrderList, IDirectusExpoOrder } from '@app/models/expo-order.interface';
import { BehaviorSubject, iif, Observable, of, throwError } from 'rxjs';
import { map, mergeMap, retryWhen, switchMap, tap } from 'rxjs/operators';
import { DirectusUserService } from '@app/providers/expo/directus/directus-user.service';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { ModeService } from '@app/providers/mode.service';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { SetRole } from '@app/store/actions/user.action';
import { UserStateModel } from '@app/store/state/user.state';
import { Order } from '../../../../assets/chepri-modules/src/models/olo.order';
import { DirectusCollection } from '@app/models/directus.collections';
import { Query } from '@directus/sdk';
import { DirectusSchema, ExpoMetadata, Locations } from '@app/providers/expo/directus/directus-collections.interface';

@Injectable({
  providedIn: 'root'
})
export class DirectusExpoProvider {
  roleID = '4'; // ID for the 'Location Role'
  errorSubject = new BehaviorSubject<string>(null);
  error$: Observable<string> = this.errorSubject.asObservable();
  private currentLocationKey = 'currentLocation';
  private currentLocationDirectusID = 'locationDirectusID';
  private currentLocation: string;

  constructor(
    private directusAPI: DirectusApiService,
    private user: DirectusUserService,
    private tranCloud: TrancloudProviderService,
    private store: Store,
    private mode: ModeService
  ) {}

  getClient() {
    return this.directusAPI.client;
  }

  // loginExpoUser(form: any): Promise<{ status: string; data: any }> {
  loginExpoUser(form: any) {
    this.errorSubject.next('');
    return this.user.loginUser(form.emailAddress, form.password, form.remember).pipe(
      switchMap(currentUser => {
        return this.user.getMe().pipe(
          map(user => {
            this.setCurrentID(user.id);
            const isMaster = user.role === '07fbb946-5744-44d6-b805-302fd48b261f';
            this.store.dispatch(new SetRole(user));
            this.setCurrentLocation(isMaster ? 'master' : user.location_restaurant_id);
            this.tranCloud.merchantID = isMaster ? null : user.location_merchant_id;
            this.tranCloud.tranDeviceID = isMaster ? null : user.location_trancloud_id;
            if (!isMaster) {
              this.tranCloud.initializePad().subscribe((initPadRes: any) => {});
            }
            this.getCurrentLocation();
            return user;
          })
        );
      })
    );
  }

  logoutExpoUser() {
    this.clearStoredLocation();
    this.clearStoredDirectusID();
    clearInterval(this.user.refreshInterval);
    return this.user.logoutUser();
  }

  getRoles() {
    return this.user.getRoles();
  }

  getExpoOrders() {
    return this.directusAPI.getItems('expo_metadata');
  }

  getExpoPastOrders(date: string, location: string) {
    const dateMoment = moment(date, 'YYYY-MM-DD');
    const queryObject = {
      filter: {
        'year(ready_time)': { _eq: dateMoment.year() },
        'month(ready_time)': { _eq: dateMoment.format('M') },
        'day(ready_time)': { _eq: dateMoment.date() },
        location: { _eq: location },
        is_kiosk: { _eq: true }
      },
      sort: '-created_on',
      limit: -1
    };
    return this.directusAPI.getQueriedItems<ExpoMetadata>('expo_metadata', queryObject);
  }

  getQueuedExpoOrders(date: string, location: string) {
    const dateMoment = moment(date, 'YYYY-MM-DD');
    const queryObject = {
      filter: {
        order_status: { _nin: ['fired', 'canceled', 'refunded'] },
        'year(ready_time)': { _eq: dateMoment.year() },
        'month(ready_time)': { _eq: dateMoment.format('M') },
        'day(ready_time)': { _eq: dateMoment.date() },
        location: { _eq: location },
        order_type: { _eq: 'dinein' }
      },
      sort: 'ready_time',
      limit: -1
    };
    return this.directusAPI.getQueriedItems<ExpoMetadata>('expo_metadata', queryObject);
  }

  getFiredExpoOrders(date: string, location: string) {
    const dateMoment = moment(date, 'YYYY-MM-DD');
    const queryObject = {
      filter: {
        order_status: { _in: ['fired', 'refunded'] },
        'year(ready_time)': { _eq: dateMoment.year() },
        'month(ready_time)': { _eq: dateMoment.format('M') },
        'day(ready_time)': { _eq: dateMoment.date() },
        order_type: { _eq: 'dinein' },
        location: { _eq: location }
      },
      sort: '-fired_time',
      limit: -1
    };
    return this.directusAPI.getQueriedItems<ExpoMetadata>('expo_metadata', queryObject);
  }

  postExpoOrder(
    pb: Order,
    user: UserStateModel,
    manualFireTime: any,
    tableNumber: string,
    invoiceNumber: string,
    cardNumber: string,
    cardType: string,
    authCode: string,
    refNo: string,
    recordNo: string
  ): Observable<any> {
    manualFireTime = manualFireTime ? manualFireTime.replace(' ', 'T') : null;
    const body: Partial<ExpoMetadata> = this.convertExpoOrder(
      pb,
      user,
      manualFireTime,
      invoiceNumber,
      tableNumber,
      cardNumber,
      cardType,
      authCode,
      refNo,
      recordNo
    );
    const queryObject = {
      filter: {
        olo_id: { _eq: body.location }
      }
    };
    return this.directusAPI.getQueriedItems<Locations>('locations', queryObject).pipe(
      switchMap(res => {
        body.location = res[0].id;
        return this.directusAPI.postItem('expo_metadata', body);
      })
    );
  }

  updateExpoOrder(body: Partial<ExpoMetadata>): Observable<ExpoMetadata> {
    return this.getExpoOrder(body.id).pipe(
      switchMap(res => {
        let key;
        const updatebody = {};
        for (key in body) {
          if (body[key] !== res[key]) {
            updatebody[key] = body[key];
          }
        }
        return this.directusAPI.patchItem<ExpoMetadata>(body.id, 'expo_metadata', updatebody);
      })
    );
  }

  getExpoOrder(id: number) {
    return this.directusAPI.getSingleItem('expo_metadata', id);
  }

  // will return an observable containing an array of one item, since order ids are unique
  getExpoOrderByOrderId(order_id: string): Observable<ExpoMetadata[]> {
    const queryObject = {
      filter: {
        order_id: { _eq: order_id }
      }
    };
    return this.directusAPI
      .getQueriedItems<ExpoMetadata>('expo_metadata', queryObject)
      .pipe(mergeMap(res => iif(() => res.length > 0, of(res), this.getExpoOrderByOrderId(order_id))));
  }

  deleteExpoOrder(body: IDirectusExpoOrder): Observable<any> {
    return this.directusAPI.deleteItem(body.id, 'expo_metadata');
  }

  getKioskCoupons() {
    return this.directusAPI.getItems('kiosk_coupons');
  }

  // being an API call, if location is master this will return the list of all locations
  // this serves a dual purpose, one is the return value, the other is to call getCurrentLocation()
  getLocationsByUserRef() {
    const locationName = this.getCurrentLocation();
    let queryObject = {};

    if (locationName !== 'master') {
      queryObject = {
        filter: {
          id: { _eq: locationName }
        }
      };
    }

    return this.directusAPI.getQueriedItems<Locations>('locations', queryObject);
  }

  getCurrentLocation(): string {
    if (this.currentLocation === undefined) {
      this.currentLocation = this.getStoredLocation();
    }
    return this.currentLocation;
  }

  setCurrentLocation(newLocation: string): void {
    this.currentLocation = newLocation;
    this.updateStoredLocation(newLocation);
  }

  setCurrentID(id: string): void {
    this.updateStoredDirectusID(id);
  }

  convertExpoOrder(
    pb: Order,
    user: UserStateModel,
    manualFireTime: any,
    invoiceNumber: string,
    tableNumber: string,
    cardNumber: string,
    cardType: string,
    authCode: string,
    refNo: string,
    recordNo: string
  ): Partial<ExpoMetadata> {
    let order: Partial<ExpoMetadata>;
    const time = this.readyTimeToDate(pb.readytime);
    const isKiosk = this.mode.getStoredMode() === 'kms' || this.mode.getStoredMode() === 'cks';
    order = {
      check_in_time: null,
      customer_name: user.dineInName.firstname + ' ' + user.dineInName.lastname,
      fired_time: null,
      location: pb.vendorid,
      order_id: pb.id,
      order_reference: pb.orderref,
      order_status: !tableNumber ? 'placed' : 'pre-fired',
      order_type: pb.deliverymode,
      party_size: user.partySize?.toString(),
      ready_time:
        manualFireTime && pb.deliverymode === 'dinein' ? manualFireTime : moment(time).format('YYYY-MM-DDTHH:mm:ss'),
      table_number: tableNumber,
      table_preference: 'any',
      is_kiosk: isKiosk,
      invoice_number: invoiceNumber, // cardNumber, cardType, authCode, refNo
      is_refunded: null,
      card_number: cardNumber,
      card_type: cardType,
      auth_code: authCode,
      ref_number: refNo,
      record_number: recordNo,
      refund_auth_code: null,
      refund_card_number: null,
      refund_card_type: null,
      refund_ref_number: null
    };
    return order;
  }

  readyTimeToDate(time: string): Date {
    return new Date(
      Number(time.substring(0, 4)),
      Number(time.substring(4, 6)) - 1,
      Number(time.substring(6, 8)),
      Number(time.substring(9, 11)),
      Number(time.substring(12))
    );
  }

  updateStoredLocation(newLocation: string) {
    localStorage.setItem(this.currentLocationKey, newLocation);
  }

  getStoredLocation(): string {
    return localStorage.getItem(this.currentLocationKey);
  }

  clearStoredLocation() {
    localStorage.removeItem(this.currentLocationKey);
  }

  updateStoredDirectusID(newID: string) {
    localStorage.setItem(this.currentLocationDirectusID, newID);
  }

  getStoredDirectusID(): string {
    return localStorage.getItem(this.currentLocationDirectusID);
  }

  clearStoredDirectusID() {
    localStorage.removeItem(this.currentLocationDirectusID);
  }
}
