import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IDirectusExpoOrder } from '@app/models/expo-order.interface';

@Component({
  selector: 'lib-check-in-modal',
  templateUrl: './check-in-modal.component.html',
  styleUrls: ['./check-in-modal.component.scss']
})
export class CheckInModalComponent implements OnInit {
  @Output() xClicked = new EventEmitter<any>();
  @Output() confirmClicked = new EventEmitter<any>();
  @Input() guestName: string;
  @Input() partySize: string;
  @Input() readyTime: string;
  @Input() tablePreference: string;
  @Input() maxTableSize: number;

  Arr = Array;
  title = 'Check-In';

  public tableNumber: string;

  constructor() {}

  ngOnInit() {}

  close(): void {
    this.xClicked.emit();
  }

  confirmButtonClicked() {
    this.confirmClicked.emit();
  }
}
