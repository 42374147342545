import { Component, OnDestroy, OnInit } from '@angular/core';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { OloApiService } from '@app/providers/expo/olo/olo-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckInModalComponent } from 'src/app/components/check-in-modal/check-in-modal.component';
import { FireModalComponent } from '@app/components/fire-modal/fire-modal.component';
import { FireNowModalComponent } from '@app/components/fire-now-modal/fire-now-modal.component';
import { CancelOrderModalComponent } from '@app/components/cancel-order-modal/cancel-order-modal.component';
import * as moment from 'moment';
import { OrderDetailsModalComponent } from '@app/components/order-details-modal/order-details-modal.component';
import { Router } from '@angular/router';
import { SetBanner } from '@app/store/actions/app.action';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { WebSocketsService } from '@app/providers/expo/websockets/websockets.service';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { OrderFireService } from '@app/providers/expo/order-fire.service';
import { DirectusSchema, ExpoMetadata } from '@app/providers/expo/directus/directus-collections.interface';
import { AuthenticationClient, DirectusClient, RestClient, WebSocketClient } from '@directus/sdk';

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit, OnDestroy {
  private client: DirectusClient<DirectusSchema> &
    RestClient<DirectusSchema> &
    AuthenticationClient<DirectusSchema> &
    WebSocketClient<DirectusSchema>;

  editOrderItem: string;
  editOrderName: string;
  maxTableSize = 8;
  Arr = Array;
  time = new Date();
  displayError = false;

  ten_minutes_in_ms = 10 * 60 * 1000;

  orderIdsFiringNow: number[] = [];
  subscriptions: Subscription[] = [];

  private unsubscribe: () => void;
  private interval: any;

  constructor(
    private expo: DirectusExpoProvider,
    private modalService: NgbModal,
    private olo: OloApiService,
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private webSocket: WebSocketsService,
    private tranCloud: TrancloudProviderService,
    public orderFire: OrderFireService
  ) {
    this.store.dispatch(new SetBanner('QUEUE', false, false, ''));
    this.client = this.expo.getClient();
  }

  ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  openOrderDetails(order: ExpoMetadata) {
    this.olo.getOrderByID(order.order_id).subscribe((oloOrder: any) => {
      const orderDetailsModal = this.modalService.open(OrderDetailsModalComponent, {
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });
      orderDetailsModal.componentInstance.guestName = order.customer_name;
      orderDetailsModal.componentInstance.readyTime = order.ready_time;
      orderDetailsModal.componentInstance.orderStatus = order.order_status;
      orderDetailsModal.componentInstance.products = oloOrder.products;
      orderDetailsModal.componentInstance.total = oloOrder.total;

      orderDetailsModal.componentInstance.xClicked.subscribe(() => {
        this.modalService.dismissAll();
      });
      orderDetailsModal.componentInstance.refundClicked.subscribe(() => {
        this.refundOrder(order);
      });
    });
  }

  checkInGuest(order: ExpoMetadata) {
    const checkInModal = this.modalService.open(CheckInModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    checkInModal.componentInstance.guestName = order.customer_name;
    checkInModal.componentInstance.partySize = order.party_size;
    checkInModal.componentInstance.readyTime = order.ready_time;
    checkInModal.componentInstance.tablePreference = order.table_preference;
    checkInModal.componentInstance.maxTableSize = this.maxTableSize;
    checkInModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    checkInModal.componentInstance.confirmClicked.subscribe(() => {
      order.customer_name = checkInModal.componentInstance.guestName;
      order.party_size = checkInModal.componentInstance.partySize;
      order.ready_time = checkInModal.componentInstance.readyTime;
      order.table_preference = checkInModal.componentInstance.tablePreference;
      order.order_status = checkInModal.componentInstance.tableNumber ? 'pre-fired' : 'arrived';
      if (checkInModal.componentInstance.tableNumber) {
        order.table_number = checkInModal.componentInstance.tableNumber;
      }
      order.check_in_time = moment().format('YYYY-MM-DDTHH:mm:ss');
      this.modalService.dismissAll();
      this.expo.updateExpoOrder(order).subscribe(() => {
        this.orderFire.runCheck();
      });
    });
  }

  fireOrder(order: ExpoMetadata) {
    if (order.order_status === 'pre-fired') {
      const fireNowOrderModal = this.modalService.open(FireNowModalComponent, {
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });
      fireNowOrderModal.componentInstance.guestName = order.customer_name;
      fireNowOrderModal.componentInstance.readyTime = order.ready_time;
      fireNowOrderModal.componentInstance.xClicked.subscribe(() => {
        this.modalService.dismissAll();
      });
      fireNowOrderModal.componentInstance.fireClicked.subscribe((table: any) => {
        this.fireNow(order);
      });
      return;
    }
    const fireOrderModal = this.modalService.open(FireModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    fireOrderModal.componentInstance.guestName = order.customer_name;
    fireOrderModal.componentInstance.partySize = order.party_size;
    fireOrderModal.componentInstance.readyTime = order.ready_time;
    if (this.remainingTime(order) >= 0) {
      fireOrderModal.componentInstance.title = 'Start Fire Timer';
      fireOrderModal.componentInstance.actionText = 'Start fire timer for\xa0'; // \xa0 is space aka nbsp;
    } else {
      fireOrderModal.componentInstance.title = 'Fire Order';
      fireOrderModal.componentInstance.actionText = 'Fire Order for\xa0';
    }

    fireOrderModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    fireOrderModal.componentInstance.fireClicked.subscribe((table: any) => {
      order.table_number = table;

      let remainingTime = this.remainingTime(order);

      if (remainingTime < 0) {
        this.fireNow(order);
        return;
      }
      order.order_status = 'pre-fired';
      this.expo.updateExpoOrder(order).subscribe(() => {
        this.orderFire.runCheck();
        remainingTime = this.remainingTime(order);
        this.fireLater(order, remainingTime);
        this.modalService.dismissAll();
      });
    });
  }

  fireLater(order: ExpoMetadata, remainingTime: number) {
    if (remainingTime < 0) {
      this.fireNow(order);
    }
    // setTimeout(() => {
    //   this.fireNow(order);
    // }, remainingTime);
  }

  fireNow(order: ExpoMetadata) {
    this.orderIdsFiringNow.push(order.id);
    this.modalService.dismissAll();
    this.olo
      .manuallyFireOrderByID(order.order_id, order.table_number, order.party_size, order.table_preference)
      .subscribe((res: any) => {
        if (res.success === true) {
          order.order_status = 'fired';
          order.fired_time = moment().format('YYYY-MM-DDTHH:mm:ss');
          this.expo.updateExpoOrder(order).subscribe(() => {
            this.orderFire.runCheck();
            this.orderIdsFiringNow.splice(this.orderIdsFiringNow.indexOf(order.id), 1);
          });
        } else {
          this.toastr.warning(res.message);
        }
      });
  }

  remainingTime(order: ExpoMetadata) {
    return moment(order.ready_time, 'YYYY-MM-DD HH:mm:ss').valueOf() - moment().valueOf() - this.ten_minutes_in_ms;
  }

  timeInSecsIsOdd() {
    return new Date().getSeconds() % 2 === 1;
  }

  fireButtonString(order: ExpoMetadata) {
    switch (order.order_status) {
      case 'pre-fired':
        const remaining = this.remainingTime(order);
        if (remaining <= 0 || this.orderIdsFiringNow.includes(order.id)) {
          // if (!this.orderIdsFiringNow.includes(order.id)) {
          //   this.fireNow(order);
          // }
          return 'Firing';
        }
        let ret = new Date(remaining).toISOString().substr(11, 8);
        if (ret[0] === '0') {
          ret = ret.substr(1);
          if (ret[0] === '0') {
            ret = ret.substr(2);
          }
        }
        return ret;
        break;
      case 'fired':
        return 'Firing';
      default:
        return 'Fire';
    }
  }

  cancelOrder(order: ExpoMetadata) {
    const cancelOrderModal = this.modalService.open(CancelOrderModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    cancelOrderModal.componentInstance.guestName = order.customer_name;
    cancelOrderModal.componentInstance.partySize = order.party_size;
    cancelOrderModal.componentInstance.readyTime = order.ready_time;
    cancelOrderModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    cancelOrderModal.componentInstance.cancelOrder.subscribe(() => {
      // cancel order
      this.olo.manuallyCancelOrderByID(order.order_id).subscribe(() => {
        order.order_status = 'canceled';
        this.expo.updateExpoOrder(order).subscribe(() => {
          this.modalService.dismissAll();
          this.orderFire.runCheck();
        });
      });
    });
  }

  editOrder(id: number) {
    if (this.editOrderItem) {
      // save active edit item
      const order = this.orderFire.expoOrders.filter(o => o.id === Number(this.editOrderItem))[0];
      this.saveOrder(order);
    }
    this.editOrderItem = id.toString();
  }

  saveOrder(order: ExpoMetadata) {
    if (this.editOrderName) {
      order.customer_name = this.editOrderName;
      this.editOrderName = '';
    }
    this.editOrderItem = null;
    this.expo.updateExpoOrder(order).subscribe(() => {
      this.orderFire.runCheck();
    });
  }

  refundOrder(order: ExpoMetadata) {
    this.olo.getOrderByID(order.order_id).subscribe((oloOrder: any) => {
      this.expo.updateExpoOrder(order).subscribe(() => {
        this.orderFire.runCheck();
        this.modalService.dismissAll();
      });
      if (order.record_number) {
        this.tranCloud
          .refundByRecordNo(order.invoice_number, order.auth_code, oloOrder.total, order.record_number)
          .subscribe(() => {
            order.order_status = 'refunded';
            this.cancelOrder(order);
          });
      } else {
        this.tranCloud.creditCardRefund(order.invoice_number, order.auth_code, oloOrder.total).subscribe(() => {
          order.order_status = 'refunded';
          this.cancelOrder(order);
        });
      }
    });
  }
}
