import { Component, Input } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { BasketStateModel } from '@app/store/state/basket.state';
import { Select, Store } from '@ngxs/store';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { Restaurant } from '@lib/models/olo.restaurant';
import { Basket } from '@lib/models/olo.basket';
import { Router } from '@angular/router';
import { ToggleBag } from '@app/store/actions/app.action';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-order-info',
  templateUrl: './order-info.component.html',
  styles: [],
  providers: [NgbActiveModal]
})
export class OrderInfoComponent {
  @Input() location: Restaurant;
  @Input() basket: Basket;
  @Input() orderInfoTop: boolean;
  @Input() orderInfoBag: boolean;

  constructor(private modal: NgbModal, private router: Router) {}

  async changeLocation() {
    this.modal.dismissAll();
    await this.router.navigateByUrl('/locations');
  }
}
