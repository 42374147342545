import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store, Select } from '@ngxs/store';
import { GetRecentOrders } from '@app/store/actions/user.action';
import { lastValueFrom, Observable, Subscriber, Subscription } from 'rxjs';
import { UserStateModel, UserState } from '@app/store/state/user.state';
import { FormatService } from '../../providers/format.service';
import { DateTime } from '../../models/DateTime';
import { orderstatus, orderstatusproduct } from '../../models/olo.recentorders';
import {
  AddProductToBasket,
  AddSavedProductToBasket,
  CreateBasket,
  CreateFromOrder,
  SetHandoffMode
} from '@app/store/actions/basket.action';
import { Router } from '@angular/router';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { environment } from '../../../../../environments/environment';
import { ResponseRetrieveSavedProductsSavedProduct } from '@app/models/saved-products.olo';
import { BasketState } from '@app/store/state/basket.state';
import { Limiter, Limiters } from '@lib/models/Limiter';
import { ProductService } from '@app/providers/product.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalStateModel } from '@app/store/state.model';
import { Basket } from '@lib/models/olo.basket';

@Component({
  selector: 'lib-past-orders',
  templateUrl: './past-orders.component.html',
  styles: []
})
export class PastOrdersComponent implements OnInit, OnDestroy {
  @Input() user: UserStateModel;
  @Input() buttons = true;
  @Input() hasPastOrders: boolean;
  @Input() basketCreated = true;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['indivdual', 'group', 'catering'];
  @Input() includesBafala = true;
  closeResult: string;
  selectedOrder: orderstatus;
  subs: any = [];
  modalReference: NgbModalRef;
  images: any;
  @Input() pastOrders: orderstatus[];

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private format: FormatService,
    private router: Router,
    private productService: ProductService,
    private toast: ToastrService
  ) {
    this.images = [];
  }

  ngOnInit() {
    this.store.dispatch(new GetRecentOrders());
    this.setImages();
  }

  ngOnDestroy() {
    this.subs.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
  clickEvent(event: any) {}

  setImages() {
    const images = this.store.selectSnapshot(RestaurantState).images.data;
    const pastOrders = this.store.selectSnapshot(UserState).recentOrders.orders;
    for (const order of pastOrders) {
      for (const image of images) {
        if (image.name === order.products[0].name) {
          this.images.push(environment.cmsDomain.concat('assets/', image.image.id));
        }
      }
    }
    // console.log('this images', this.images);
  }

  pastOrderExpand(pastOrderPopup: any, order: orderstatus) {
    this.selectedOrder = order;
    this.modalReference = this.modalService.open(pastOrderPopup, { ariaLabelledBy: 'pastOrderModal', centered: true });
    this.modalReference.result.then(
      (result: any) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason: any) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  parseDate(oloDateTime: string): string {
    const dateTime = DateTime.fromOlo(oloDateTime);
    if (!!dateTime && dateTime.shortDateString) {
      return dateTime.shortDateString;
    }
    return null;
  }

  handleReorder(order?: orderstatus) {
    // Get current selected order,
    // Dispatch call to Olo to create basket from previous order
    // Navigate to order details page to begin checkout flow
    // const orderId = this.pastOrders[ this.selectedOrder ].id;

    // If order is provided, set selected
    if (order) {
      this.selectedOrder = order;
    }
    const token: string = this.store.selectSnapshot(state => state.user.info.authtoken);
    const orderId = this.selectedOrder.id;
    const orderRef = this.selectedOrder.orderref;
    this.subs.push(
      this.store.dispatch(new CreateFromOrder(orderRef, orderId, token)).subscribe(res => {
        if (res) {
          if (this.modalReference) {
            this.modalReference.dismiss();
          }
          this.router.navigateByUrl('/order-details');
        }
      })
    );
  }

  async addRecentOrderItemsToCart(order: orderstatus, maxOrder?: any): Promise<void> {
    if (!this.basketCreated) {
      try {
        await lastValueFrom(this.store.dispatch(new CreateBasket(String(order.vendorid), this.user.info.authtoken)));
        await lastValueFrom(this.store.dispatch(new SetHandoffMode(order.deliverymode)));
      } catch (e) {
        this.toast.error(
          `Could not start an order at ${order.vendorname}. Please start an order at a different location and come back to add the items from this order.`
        );
        return;
      }
    }
    let itemsAdded = 0;
    for (const prod of order.products) {
      try {
        const product = this.productService.getProductFromChainProductID(prod.chainproductid);

        // Retrieve the current state
        const state = this.store.selectSnapshot(BasketState);
        const capacity = state.capacity._items;
        const limiters = new Limiters(capacity);

        // Check if maxOrder and product metadata exist and if the category limiter applies
        if (maxOrder && product.metadata && limiters.hasCategory(parseInt(product.metadata[0].value))) {
          const categoryId = parseInt(product.metadata[0].value);
          const limiterState = limiters.items[limiters.find(categoryId)];
          const limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);

          // Check if the limiter is full or cannot add more products
          if (limiter.isFull() || !limiter.canAdd()) {
            this.maxOrderLimit(maxOrder);
            this.toast.error(`You have reached the maximum limit for this category. Could not add ${product.name}.`);
            break; // Exit the loop if the limiter is full
          }
        }

        // Prepare the basket product
        const basketProduct = this.productService.convertProductToBasketProductToAdd(
          product,
          prod.choices.map(choice => String(choice.id)),
          prod.quantity
        );

        // Dispatch the action and await its completion
        await lastValueFrom(this.store.dispatch(new AddProductToBasket(basketProduct, product.metadata || [])));
        itemsAdded++;
        // Dismiss the modal if it exists
        if (this.modalReference) {
          this.modalReference.dismiss();
        }
      } catch (error) {
        // Handle the error (e.g., log it, show a notification)
        console.error('Failed to add product to cart:', error);

        // Optionally, you can call maxOrderLimit here if needed
        if (maxOrder) {
          this.maxOrderLimit(maxOrder);
        }

        // Exit the loop on error
        break;
      }
    }
    if (itemsAdded > 0) {
      this.toast.success(`Added ${itemsAdded} items`);
    }
  }

  maxOrderLimit(maxOrder: any) {
    this.modalService.open(maxOrder, { ariaLabelledBy: 'maxOrder', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getOrderNameDismissReason(reason)}`;
      }
    );
  }

  private getOrderNameDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      // this.addProductToCart();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // this.addProductToCart();
      return 'by clicking on a backdrop';
    } else {
      // this.addProductToCart();
      return `with: ${reason}`;
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
