import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GetRecentOrders } from '@app/store/actions/user.action';
import { UserStateModel } from '@app/store/state/user.state';
import { Observable } from 'rxjs';
import { SetBanner } from '@app/store/actions/app.action';
import { EmptyPreviousBasket } from '@app/store/actions/basket.action';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { GlobalStateModel } from '@app/store/state.model';
import { Basket } from '@lib/models/olo.basket';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @Select() user$: Observable<UserStateModel>;
  @Select((state: GlobalStateModel) => state.basket.basket) basket$: Observable<Basket>;
  @Input() accountItems = [
    {
      text: 'Account Details',
      fragment: 'details'
    },
    {
      text: 'Recent Orders',
      fragment: 'recent'
    },
    {
      text: 'Favorite Orders',
      fragment: 'fave'
    },
    {
      text: 'Favorite Items',
      fragment: 'saved'
    },
    {
      text: 'Saved Credit Cards',
      fragment: 'cards'
    }
  ];
  private subs: Subscription[] = [];

  @Output() itemClick = new EventEmitter<any>();
  selected = 0;
  constructor(private store: Store, private route: ActivatedRoute, private router: Router) {
    this.subs.push(
      this.route.fragment.subscribe(fragment => {
        switch (fragment) {
          case 'fave':
            this.selected = this.accountItems.findIndex(item => item.fragment === 'fave');
            break;
          case 'recent':
            this.selected = this.accountItems.findIndex(item => item.fragment === 'recent');
            break;
          case 'saved':
            this.selected = this.accountItems.findIndex(item => item.fragment === 'saved');
            break;
          case 'cards':
            this.selected = this.accountItems.findIndex(item => item.fragment === 'cards');
            break;
          default:
            this.selected = 0;
            break;
        }
      })
    );
  }

  clicked(index: number, item: { text: string; fragment: string }) {
    this.selected = index;
    this.router.navigate(['/account'], { fragment: item.fragment });
  }

  ngOnInit() {
    this.store.dispatch(new SetBanner('ACCOUNT DETAILS', false, true, ''));
    this.store.dispatch(new EmptyPreviousBasket());
  }
}
