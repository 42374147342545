<script src="https://cdnjs.cloudflare.com/ajax/libs/web-animations/2.2.2/web-animations.min.js"></script>
<ng-container *ngIf="mode !== 'cks' && mode !== 'wait-time'">
  <nav
    class="navbar navbar-collapse-xl navbar-light fixed-top navnav px-3"
    [ngClass]="{ 'navbar-expand-lg': !centerJustified }"
  >
    <ng-container *ngIf="!centerJustified && mode !== 'kms'">
      <button
        id="menuBtn"
        *ngIf="!bagNavbar"
        (click)="toggleMenu()"
        class="navbar-toggler hamburger"
        type="button"
        data-toggle="collapse"
        data-target="#navbar1"
        aria-controls=".navbar-collapse"
        [attr.aria-expanded]="!centerJustified"
        aria-label="Toggle navigation"
      >
        <lib-svg-sprite [@switchIconHam]="navIcon" svgID="ham-icon"></lib-svg-sprite>
        <lib-svg-sprite [@switchIconX]="navIcon" svgID="popup-x"></lib-svg-sprite>
      </button>
    </ng-container>

    <ng-container *ngIf="mode === 'kms'">
      <button
        id="menuBtn"
        *ngIf="!bagNavbar"
        (click)="toggleMenu()"
        class="kiosk-navbar-toggler hamburger"
        type="button"
        data-toggle="collapse"
        data-target="#navbar1"
        aria-controls=".navbar-collapse"
        [attr.aria-expanded]="!centerJustified"
        aria-label="Toggle navigation"
      >
        <lib-svg-sprite [@switchIconHam]="navIcon" svgID="ham-icon"></lib-svg-sprite>
        <lib-svg-sprite [@switchIconX]="navIcon" svgID="popup-x"></lib-svg-sprite>
      </button>
    </ng-container>

    <!--
    <div class="returnToPunch">
      <p class="returnTo">RETURN TO</p>
      <a href="https://www.punchpizza.com" class="punchlink">punchpizza.com</a>
    </div>
    -->
    <ng-container *ngIf="mode !== 'kms'">
      <a href="https://www.punchpizza.com">
        <button class="returnToPunchButton">Return To Punch.com</button>
      </a>
    </ng-container>

    <div class="navbar-brand brand" *ngIf="mode !== 'kms'">
      <lib-logo class="px-3"></lib-logo>
      <ng-container *ngIf="!centerJustified"></ng-container>
    </div>

    <ng-container *ngIf="mode === 'kms'">
      <!--
      <a class="logoutBtn btn" (click)="logoutClicked()"></a>
      -->

      <div class="navbar-brand kiosk-brand">
        <lib-svg-sprite svgID="logo-svg" *ngIf="bagDisplay"></lib-svg-sprite>
        <ng-container *ngIf="!centerJustified"></ng-container>
      </div>

      <div class="ms-auto" *ngIf="!isKioskFlow()">
        <button (click)="returnToKiosk()" class="returnToKioskBtn btn p-2">Kiosk Mode</button>
      </div>
      <div class="ms-auto" *ngIf="isKioskFlow() && !(isMaster$ | async)">
        <div *ngIf="basket$ | async as basket">
          <button
            (click)="routeToKioskCheckout()"
            class="kioskBasketBtn btn p-2"
            [disabled]="basket?.basket?.total === 0"
          >
            {{ (basket?.basketValidation?.total ? basket.basketValidation.total : basket.basket?.total) | currency }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mode !== 'kms'">
      <div class="collapse navbar-collapse linkItems" id="navbarTogglerData">
        <ul class="navbar-nav">
          <lib-link class="nav-link menuCaps link-color-override w-auto" text="locations" routerLink="/locations">
          </lib-link>
          <ng-container *ngIf="(restaurant$ | async)?.menu">
            <lib-link class="nav-link menuCaps link-color-override w-auto" text="menu" routerLink="/menu"> </lib-link>
          </ng-container>
          <lib-link
            class="nav-link menuCaps link-color-override w-auto"
            text="faq"
            url="https://www.punchpizza.com/faq"
            target="_blank"
          >
          </lib-link>
        </ul>

        <div class="ms-auto me-3">
          <div class="col" style="display: flex; justify-content: center">
            <lib-auth-nav
              (authNavClick)="handleAuth($event)"
              [buttons]="authButtons"
              [accountText]="accountText"
              [giftText]="giftText"
              [logoutText]="logoutText"
              [signinText]="signinText"
              [signupText]="signupText"
            >
            </lib-auth-nav>
          </div>
        </div>
      </div>
      <div *ngIf="!bagNavbar && bagDisplay" class="me-3" style="display: grid; place-items: center">
        <ng-container *ngTemplateOutlet="bagComponent"></ng-container>
      </div>
      <button *ngIf="bagNavbar" class="navbar-toggler hamburger" (click)="onClose()">
        <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
      </button>
    </ng-container>
  </nav>
</ng-container>
<lib-menu-slide
  (closeBar)="toggleMenu()"
  (kioskSignOutEmit)="logoutClicked()"
  [mode]="mode"
  [@slideInOut]="menuState"
></lib-menu-slide>

<ng-template #bagComponent>
  <ng-container *ngIf="location.pathname !== '/locations'">
    <a class="clickable" id="bagIcon" (click)="toggleBag()" data-toggle="modal" data-target="#exampleModal">
      <lib-cart-nav
        [buttons]="cartButtons"
        [orderText]="orderText"
        [productCount]="productCount"
        [dropdownChildren]="dropdownChildren"
      ></lib-cart-nav>
    </a>
  </ng-container>
</ng-template>
