<div *ngIf="orderInfoTop" class="orderInfoTop">
  <p class="orderInfo white" *ngIf="basket.deliverymode !== 'dinein'" id="boldInfo">
    {{ basket.deliverymode === 'dispatch' ? 'Delivering' : 'Pickup' }} From - {{ location.name }}
  </p>
  <p class="orderInfo white" *ngIf="basket.deliverymode === 'dinein'" id="boldInfo">Dine-In At - {{ location.name }}</p>
  <p class="orderInfo white">
    {{ location.streetaddress }}
  </p>
  <p class="orderInfo white">{{ location.city }}, {{ location.state }} {{ location.zip }}</p>
  <p class="orderInfo white">
    {{ location.telephone }}
  </p>
  <a class="whitecolor" href="/locations">Change Location</a>
</div>

<div *ngIf="orderInfoBag" class="orderInfoBag">
  <div style="display: inline-flex; flex-direction: row">
    <lib-svg-sprite style="position: relative; bottom: 3px;" svgID="location"></lib-svg-sprite>
    <p class="orderInfo ps-2" *ngIf="basket.deliverymode !== 'dinein'" id="boldInfo">
      {{ basket.deliverymode === 'dispatch' ? 'Delivering' : 'Pickup' }} From - {{ location.name }}
    </p>
    <p class="orderInfo ps-2" *ngIf="basket.deliverymode === 'dinein'" id="boldInfo">Dine-In - {{ location.name }}</p>
  </div>
  <p class="orderInfo ">
    {{ location.streetaddress }}
  </p>
  <p class="orderInfo ">{{ location.city }}, {{ location.state }} {{ location.zip }}</p>
  <p class="orderInfo ">
    {{ location.telephone }}
  </p>
  <div class="text-decoration-underline cursor-pointer" (click)="changeLocation()">Change Location</div>
</div>
