<div class="container-fluid">
  <div class="favoriteOrdersRow">
    <div *ngIf="(user$ | async).favMeals?.faves as faves">
      <ng-container *ngIf="faves.length > 0; else noAccountInfo">
        <div *ngIf="(restaurant$ | async).restaurant as restaurant; else noLocation">
          <div
            class="d-flex flex-lg-row flex-wrap flex-column justify-content-center align-items-center align-items-lg-start justify-content-lg-start gap-3"
          >
            <div class="col card m-0 favoriteOrderCard" style="min-width: 300px;" *ngFor="let order of faves">
              <div class="favoriteOrderName">{{ order.name }}</div>

              <div class="card-body" style="float: bottom; padding-top: 0;">
                <div class="d-flex flex-row mb-3" style="height:150px; align-content: center;">
                  <div class="d-flex flex-column justify-content-center align-items-center w-100">
                    <div *ngFor="let items of order.products" class="mb-1">
                      <div style="font-weight: bold;">{{ items.name }}</div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="col-12">
                    <div class="d-flex flex-row">
                      <div class="col-12">
                        <lib-button (click)="favoriteOrderExpand($event, favoriteOrderPopup, order)" text="REORDER">
                        </lib-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="col-12">
                    <div class="d-flex flex-row">
                      <div class="col-12 viewMore">
                        <a class="remove-favorite-button" (click)="deleteFavoriteOrder($event, order.id)"
                          >Remove Favorite</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #noLocation>
  <div class="d-flex flex-row">
    <div class="col-lg-8 col-md-12 noAccountInfo w-100">
      <div class="accountDetailsInnerInfo">
        <div class="accountDetailsHeader">No Location Selected</div>
        <div class="noAccountInfoLine"></div>
      </div>
      <div class="no-location-text">Please Select a location</div>
      <lib-button text="LOCATIONS" (click)="goToLocation()"></lib-button>
    </div>
    <div class="col-lg-4 col-md-0"></div>
  </div>
</ng-template>

<ng-template #noAccountInfo>
  <div class="d-flex flex-row">
    <div class="col-lg-8 col-md-12 noAccountInfo w-100">
      <div class="accountDetailsInnerInfo">
        <div class="accountDetailsHeader">No Favorite Orders</div>
        <div class="noAccountInfoLine"></div>
      </div>
    </div>
    <div class="col-lg-4 col-md-0"></div>
  </div>
</ng-template>

<ng-template class="pup" #favoriteOrderPopup let-favoriteOrderModal>
  <lib-favorite-order-modal
    (handleFavroiteOrder)="handleFavoriteOrderSubmit(favoriteOrderModal)"
    (xClicked)="favoriteOrderModal.dismiss()"
  ></lib-favorite-order-modal>
</ng-template>
