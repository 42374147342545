<lib-sub-nav
  class="menuSubnav"
  [capital]="true"
  [links]="catStrings"
  (subNavClick)="subNavClicked($event)"
  [hasScrolled]="hasScrolled"
>
</lib-sub-nav>
<div class="background">
  <div class="menu-product-container px-0">
    <div class="d-flex flex-row">
      <div class="col-12 px-0 mobileMenuText">
        <div class="menutitle">
          <h2 class="menuh2">{{ catStrings[selectedCat] }}</h2>
          <a
            *ngIf="!(catStrings[selectedCat] === 'Dessert' || catStrings[selectedCat] === 'Beverages')"
            class="clickable"
            (click)="open(content)"
          >
            <lib-svg-sprite *ngIf="catStrings[selectedCat] === 'Classic Pizzas'" svgID="information"></lib-svg-sprite>
          </a>
        </div>
        <p class="small px-3" style="text-align: center">{{ categories[selectedCat]?.description }}</p>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="col-12 p-0">
        <div class="noPaddingCol productCardsRow">
          <div *ngFor="let product of categories[selectedCat]?.products; trackBy: productCardTrackBy" class="cardsyo">
            <lib-product-card
              [product]="product"
              [defaultImageUrl]="defaultImageUrl"
              [imagePath]="product.imagefilename ? imagePath : ''"
              text="Quick Add"
              (addToBag)="addToBag(product)"
              (customize)="customize(product)"
              [isLoading]="selectedProdID === product.id"
            >
            </lib-product-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template class="pup" #content let-modal>
  <div class="popupShell">
    <a class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="text-align: right;">
      <div class="popup-x-modal"><lib-svg-sprite svgID="popup-x"></lib-svg-sprite></div>
    </a>
    <div class="popupHeader">
      Information
    </div>
    <div class="popupInnerInfo">
      <p class="popupLine">Additions and substitutions extra charge</p>
      <p class="popupLine">* Item put on after pizza comes out of the oven</p>
      <p class="popupLine">
        <lib-svg-sprite svgID="di-bafala"></lib-svg-sprite>Indicates item is made with mozzarella di bufala
      </p>
    </div>
  </div>
</ng-template>

<ng-template #savedProductsCategory>
  <ng-container *ngIf="savedProducts$ | async as savedProducts">
    <div class="d-flex flex-row">
      <div class="col-12 px-0 mobileMenuText">
        <div class="menutitle">
          <h2 class="menuh2">Favorite Items</h2>
          <a class="clickable" (click)="open(content)">
            <lib-svg-sprite svgID="information"></lib-svg-sprite>
          </a>
        </div>
        <p class="small" style="text-align: center"></p>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="col-12 p-0">
        <ng-container *ngIf="savedProducts.length > 0; else noSavedProducts">
          <div class="noPaddingCol productCardsRow">
            <div *ngFor="let product of savedProducts; trackBy: savedProductCardTrackBy" class="cardsyo">
              <lib-saved-product-card
                [product]="product"
                [defaultImageUrl]="defaultImageUrl"
                text="Quick Add"
                (addToBag)="addSavedToBag(product)"
                [isLoading]="selectedProdID === product.id"
              >
              </lib-saved-product-card>
            </div>
          </div>
        </ng-container>
        <ng-template #noSavedProducts>
          <div class="text-center">
            <p>You have no saved products</p>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-template>
